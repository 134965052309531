<template>
  <DetailTemplate
    :customClass="'purchase-order-detail detail-page'"
    v-if="getPermission('purchase-order:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton"
            width="400"
            height="28"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mr-4">
            {{ detail.barcode }}
          </h1>
          <CustomStatus
            :status.sync="detail.status"
            endpoint="purchase-order/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 65vh; position: relative"
      > -->
      <v-container fluid>
        <v-row dense>
          <v-col cols="5" class="pb-0">
            <v-container fluid>
              <v-list flat>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <router-link
                              :to="
                                getDefaultRoute('supplier.detail', {
                                  params: {
                                    id: detail.supplier
                                      ? detail.supplier.id
                                      : 0,
                                  },
                                })
                              "
                              >{{
                                detail.supplier ? detail.supplier.full_name : ""
                              }}</router-link
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('supplier.detail', {
                                params: {
                                  id: detail.supplier ? detail.supplier.id : 0,
                                },
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Attention</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28"
                            >mdi-office-building-outline</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                          >
                            <router-link
                              :to="
                                getDefaultRoute('supplier.detail', {
                                  params: {
                                    id: detail.supplier
                                      ? detail.supplier.id
                                      : 0,
                                  },
                                })
                              "
                              >{{
                                detail.supplier
                                  ? detail.supplier.company_name
                                  : ""
                              }}</router-link
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action
                          link
                          class="m-0 ml-4"
                          v-on:click="
                            $router.push(
                              getDefaultRoute('supplier.detail', {
                                params: {
                                  id: detail.supplier ? detail.supplier.id : 0,
                                },
                              })
                            )
                          "
                        >
                          <v-icon class="color-custom-blue"
                            >mdi-link mdi-rotate-135</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <span>Company Name</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <a :href="'mailto:' + supplierEmail">{{
                              supplierEmail
                            }}</a>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contact Email</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        class="py-1 max-content-width"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28">mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                          >
                            <a :href="'tel:' + supplierPhone">{{
                              supplierPhone
                            }}</a>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Contact Phone</span>
                  </v-tooltip>
                </template>
                <v-skeleton-loader
                  class="custom-skeleton height-40px custom-skeleton-full-width"
                  type="text"
                  v-if="pageLoading"
                >
                </v-skeleton-loader>
                <template v-else>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item class="py-1" v-bind="attrs" v-on="on">
                        <v-list-item-icon class="margin-auto-zero mr-4">
                          <v-icon class="font-size-28"
                            >mdi-home-map-marker</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content class="p-0">
                          <v-list-item-title
                            class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                          >
                            <a
                              target="_blank"
                              :href="
                                'https://maps.google.com/?q=' + supplierAddress
                              "
                              >{{ supplierAddress }}</a
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>Supplier Address</span>
                  </v-tooltip>
                </template>
              </v-list>
            </v-container>
          </v-col>
          <v-col cols="7" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 4"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100">
                      <tr>
                        <td class="font-size-18 pt-3 pb-1" width="200">
                          Purchase Order #
                        </td>
                        <td class="font-weight-600 font-size-18 pt-3 pb-1">
                          {{ detail.barcode }}
                        </td>
                      </tr>
                      <tr v-if="detail.project">
                        <td class="font-size-18 py-1" width="200">Project #</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ detail.project.barcode }} -
                          {{ detail.project.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          SOled Job No.
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template v-if="detail.reference">{{
                            detail.reference
                          }}</template>
                          <template v-else
                            ><em class="text--secondary font-weight-500"
                              >No SOled Job No.</em
                            ></template
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Supplier Ref #
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template v-if="detail.reference">{{
                            detail.supplier_reference
                          }}</template>
                          <template v-else
                            ><em class="text--secondary font-weight-500"
                              >No Supplier Ref #</em
                            ></template
                          >
                        </td>
                      </tr>
                      <tr v-if="lodash.isEmpty(detail.payment_term) === false">
                        <td class="font-size-18 py-1" width="200">
                          Payment Term
                        </td>
                        <td
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template v-if="detail.payment_term">{{
                            detail.payment_term.text
                          }}</template>
                          <template v-else
                            ><em class="text--secondary font-weight-500"
                              >No Payment Term</em
                            ></template
                          >
                        </td>
                      </tr>
                      <tr class="custom-border-top">
                        <td
                          class="font-size-18 py-1 custom-border-right pr-4"
                          width="200"
                        >
                          Purchase order date
                        </td>
                        <td
                          class="font-size-18 px-4 py-1 custom-border-right"
                          width="200"
                        >
                          Expected delivery date
                        </td>
                        <td class="font-size-18 px-4 py-1" width="200">
                          Due date
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 pr-4 custom-border-right"
                        >
                          {{ formatDate(detail.date) }}
                        </td>
                        <td
                          width="300"
                          class="font-weight-600 font-size-18 py-1 px-4 custom-border-right"
                        >
                          <template v-if="detail.expected_delivery_date">{{
                            formatDate(detail.expected_delivery_date)
                          }}</template>
                          <template v-else
                            ><em class="text--secondary font-weight-500"
                              >no Expected delivery date</em
                            ></template
                          >
                        </td>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 px-4"
                        >
                          <template v-if="detail.due_date">{{
                            formatDate(detail.due_date)
                          }}</template>
                          <template v-else
                            ><em class="text--secondary font-weight-500"
                              >no due date</em
                            ></template
                          >
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" class="mt-8">
            <v-tabs
              active-class="custom-tab-active"
              v-model="purchaseOrderTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#overview"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/purchase-order.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#line-item"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <v-badge
                  v-if="detail.line_items_count"
                  bordered
                  color="orange darken-4"
                  :content="detail.line_items_count"
                >
                  {{ detail.line_items_count > 1 ? "Line Item" : "Line Item" }}
                </v-badge>
                <template v-else> Line Item </template>
              </v-tab>
              <v-tab
                v-if="showPendingTab"
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#pending-line-item"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <v-badge
                  v-if="detail.line_items_count"
                  bordered
                  color="orange darken-4"
                  :content="detail.line_items_count"
                >
                  {{ detail.line_items_count > 1 ? "Line Item" : "Line Item" }}
                </v-badge>
                <template v-else> Pending Line Item </template>
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#received-line-item"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/line-item.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                <v-badge
                  v-if="detail.line_items_count"
                  bordered
                  color="orange darken-4"
                  :content="detail.line_items_count"
                >
                  {{ detail.line_items_count > 1 ? "Line Item" : "Line Item" }}
                </v-badge>
                <template v-else> Received Line Item </template>
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#history"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/history.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                History
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="purchaseOrderTab">
              <v-tab-item value="overview">
                <LineItemOnlyView
                  isPurchaseOrder
                  type="purchase_order"
                  :detail.sync="detail"
                  :pageLoading.sync="pageLoading"
                ></LineItemOnlyView>
                <TermConditionDetail
                  type="purchase-order"
                  :detail.sync="detail"
                  :canUpdate.sync="canUpdatePurchaseOrder"
                ></TermConditionDetail>
                <InternalNoteAttachmentDetail
                  isPurchaseOrder
                  type="purchase-order"
                  :detail.sync="detail"
                  :canUpdate.sync="canUpdatePurchaseOrder"
                ></InternalNoteAttachmentDetail>
              </v-tab-item>
              <v-tab-item value="line-item">
                <LineItemDetail
                  isPurchaseOrder
                  type="purchase_order"
                  :detail.sync="detail"
                  :pageLoading.sync="pageLoading"
                  v-on:load:parent="getPurchaseOrder"
                  :canUpdate.sync="canUpdatePurchaseOrder"
                ></LineItemDetail>
              </v-tab-item>
              <v-tab-item value="pending-line-item">
                <PendingLineItem
                  :purchaseOrder.sync="purchaseOrder"
                  v-on:reload:content="getPurchaseOrder"
                ></PendingLineItem>
              </v-tab-item>
              <v-tab-item value="received-line-item">
                <ReceivedLineItem
                  :purchaseOrder.sync="purchaseOrder"
                ></ReceivedLineItem>
              </v-tab-item>
              <v-tab-item value="history">
                <InternalHistoryDetail
                  type="purchase-order"
                  :type_id.sync="detail.id"
                ></InternalHistoryDetail>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <!-- </perfect-scrollbar> -->
    </template>
  </DetailTemplate>
</template>

<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import LineItemDetail from "@/view/pages/partials/Detail/Line-Item-Detail.vue";
import TermConditionDetail from "@/view/pages/partials/Detail/Term-Condition-Detail.vue";
import InternalNoteAttachmentDetail from "@/view/pages/partials/Detail/Internal-Note-Attachment-Detail.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import PendingLineItem from "@/view/pages/purchase-order/Detail/Pending-Line-Item";
import ReceivedLineItem from "@/view/pages/purchase-order/Detail/Received-Line-Item";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import LineItemOnlyView from "@/view/pages/partials/Line-Item-Only-View.vue";

export default {
  mixins: [CommonMixin],
  data: () => {
    return {
      purchaseOrder: 0,
      pageLoading: true,
      detail: new Object(),
      moreActions: new Array(),
      updateDetailDialog: false,
    };
  },
  methods: {
    getPurchaseOrder() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, { url: "purchase-order/" + _this.purchaseOrder })
        .then(({ data }) => {
          _this.detail = data;
          _this.moreActions = data.more_actions;
        })
        .catch((error) => {
          _this.goBack();
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("purchase.order.create", {
              query: {
                duplicate: _this.detail.id,
              },
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("purchase.order.update", {
              params: {
                id: _this.detail.id,
              },
            })
          );
          break;
        case "mark_as_approved":
          _this.updatePurchaseOrderStatus(2);
          break;
        case "mark_as_closed":
          _this.updatePurchaseOrderStatus(3);
          break;
        case "mark_as_cancel":
          _this.updatePurchaseOrderStatus(4);
          break;
        case "download_pdf":
          window.open(
            process.env.VUE_APP_API_URL +
              "purchase-order/" +
              _this.detail.id +
              "/pdf/download",
            "_blank"
          );
          break;
        case "print":
          window.open(
            process.env.VUE_APP_API_URL +
              "purchase-order/" +
              _this.detail.id +
              "/print",
            "_blank"
          );
          break;
      }
    },
    updatePurchaseOrderStatus(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "purchase-order/" + _this.purchaseOrder,
          data: { status },
        })
        .then(() => {
          _this.getPurchaseOrder();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    LineItemOnlyView,
    PendingLineItem,
    ReceivedLineItem,
    DetailTemplate,
    CustomStatus,
    LineItemDetail,
    TermConditionDetail,
    InternalNoteAttachmentDetail,
    InternalHistoryDetail,
  },
  created() {
    const _this = this;
    _this.purchaseOrder = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.purchaseOrder || _this.purchaseOrder <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.purchaseOrderTab = _this.$route.query.tab;
    }
  },
  mounted() {
    const _this = this;
    _this.getPurchaseOrder();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Purchase Order",
        route: "purchase.order",
      },
      {
        title: "Detail",
      },
    ]);
  },
  computed: {
    supplierAddress() {
      let result = new Array();
      if (this.detail && this.detail.supplier) {
        if (this.detail.supplier.unit_number) {
          result.push(this.detail.supplier.unit_number);
        }
        if (this.detail.supplier.address_line_1) {
          result.push(this.detail.supplier.address_line_1);
        }
        if (this.detail.supplier.address_line_2) {
          result.push(this.detail.supplier.address_line_2);
        }
        if (this.detail.supplier.postal_code) {
          result.push(this.detail.supplier.postal_code);
        }
      }
      return result.join(", ");
    },
    supplierEmail() {
      if (this.detail && this.detail.supplier) {
        if (this.detail.supplier.primary_email) {
          return this.detail.supplier.primary_email.value;
        }
      }
      return null;
    },
    supplierPhone() {
      if (this.detail && this.detail.supplier) {
        return this.detail.supplier.phone;
      }
      return null;
    },
    canUpdatePurchaseOrder() {
      if (this.detail && (this.detail.status == 3 || this.detail.status == 4)) {
        return false;
      }
      return this.getPermission("purchase-order:update");
    },
    showPendingTab() {
      if (this.detail.status == 1) {
        return false;
      }
      if (this.detail.status == 6) {
        return false;
      }
      if (this.detail.pending_line_items > 0) {
        return true;
      }
      return false;
    },
    purchaseOrderTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.purchaseOrderTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "overview";
      },
    },
  },
};
</script>
